<template>
  <div>
    <div class="flix-container flix-form-group" v-if="!password">
      <div class="flix-row">
        <div class="flix-col-md-4 flix-col-md-flix-offset-4">
          <div class="flix-well flix-text-center">
            <div class="flix-form-group">
              <div class="flix-html-h1" >
                <flixIcon :id="'lock'"/>
              </div>
              <div class="flix-html-h2">
                {{ $t('message.signIn') }}
              </div>
            </div>
            <form @submit.prevent="sendLogin()" :class="{'flix-error': error}">
              <div class="flix-form-group">
                <input type="text" autocomplete="username" v-model="username" class="flix-form-control" spellcheck="false" :placeholder="$t('message.username') + ' / ' + $t('message.email')"/>
              </div>
              <transition name="flixFadeIn">
              <div class="flix-form-group" v-if="username">
                <input autocomplete="current-password" type="password" v-model="pass" class="flix-form-control" spellcheck="false" :placeholder="$t('message.password')"/>
              </div>
              </transition>
              <transition name="flixFadeIn">
                <div class="flix-form-group" v-if="username && pass">
                  <button type="submit" class="flix-btn flix-btn-block flix-btn-default">{{ $t("message.signIn") }}</button>
                </div>
              </transition>
            </form>
            <div class="flix-form-group" v-if="error">
              <a href="#" @click.prevent="password = !password" class="flix-html-a"><flixIcon :id="'keys'"/> {{ $tc('message.forgotPassword', 2) }}</a>
            </div>
            <div v-if="sending" class="flix-text-center flex flex-center" style="width: 100%">
              <b-flix-loader></b-flix-loader>
            </div>
          </div>
        </div>
      </div>
    </div>
      <transition name="flixFadeIn">
        <passwordForm :key="password" v-if="password"/>
      </transition>
  </div>
</template>
<script>
export default {
  components: {
    passwordForm () { return import('@/components/signIn/password') }
  },
  props: { callback: Function },
  data () {
    return {
      username: '',
      pass: '',
      error: false,
      password: false,
      sending: false
    }
  },
  watch: {
    username (val) { this.username = val.toLowerCase() }
  },
  methods: {
    passwordForm () {
      this.$router.push({ name: 'dashboardSignIn' })
    },
    sendLogin () {
      this.error = false
      this.sending = true
      this.$flix_post({
        url: '/user/get_login',
        data: { login: btoa(this.username + ':' + this.pass) },
        callback: function (ret) {
          if (ret.data[0]) {
            if (typeof ret.data[2] !== 'undefined') {
              window.localStorage.setItem('flix_token', ret.data[2])
            }
            if (typeof ret.data[1].language === 'string') {
              this.$i18n.locale = ret.data[1].language
            }
            window.localStorage.setItem('flix_login', btoa(JSON.stringify(ret.data[1])))
            this.$store.commit('setShareEmail', ret.data[1].email)
            this.$store.commit('setUser', ret.data[1])
            this.$store.dispatch('getAssistents', function () {
              this.$store.dispatch('getBookings', function () {
                this.sending = false
                this.callback()
              }.bind(this))
            }.bind(this))
          } else {
            this.error = true
          }
        }.bind(this)
      })
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
  .flix-error
    animation: 0.8s ferror ease-in-out

  @keyframes ferror
    10%, 90%
      transform: translate3d(-2px, 0, 0)

    20%, 80%
      transform: translate3d(4px, 0, 0)

    30%, 50%, 70%
      transform: translate3d(-10px, 0, 0)

    40%, 60%
      transform: translate3d(10px, 0, 0)
</style>
